import * as React from 'react';
import { graphql, HeadFC, PageProps } from 'gatsby';
import Layout from '../layouts/Layout';
import SEO from '../components/SEO/SEO';
import Hero from '../components/Hero/Hero';
import ContentRepeater from '../components/ContentRepeater/ContentRepeater';
import { PageContentProvider } from '../context/PageContentContext';
import { CompatibilityAssessmentProvider } from '../context/CompatibilityAssessmentContext';
import { CompatibilityAssessmentQueryType } from '../types/contentful/PageTypes/CompatibilityAssessmentQueryType';

interface Props extends PageProps {
    data: CompatibilityAssessmentQueryType
}

const CompatibilityAssessmentPage = ({ data }: Props) => {
    const { contentfulPage } = data;
    return (
        <PageContentProvider pageContent={contentfulPage} fullGraphQlData={data}>
            <CompatibilityAssessmentProvider>
                <Layout>
                    <Hero
                        title={contentfulPage?.pageTitle}
                        description={contentfulPage?.pageDescription}
                        image={contentfulPage?.pageImage}
                    />

                    <ContentRepeater content={contentfulPage?.content} />
                </Layout>
            </CompatibilityAssessmentProvider>
        </PageContentProvider>
    );
};

export default CompatibilityAssessmentPage;


// gatsby page query
export const pageQuery = graphql`
    query CompatibilityAssessmentPageQuery {
        site {
            siteMetadata {
                title
                description
                url
                image
            }
        }
        contentfulPage(internalName: {eq: "Compatibility Assessment Page"}) {
            ...pageFields
            ...pageContent
        }
        allContentfulReddStandard {
            nodes {
                id
                name
                body {
                    raw
                }
                table {
                    raw
                }
                websiteLink
                internalName
                pdfDownload {
                    file {
                        url
                        details {
                            size
                        }
                    }

                }
                compatibilityAssessmentResultsPDFDownload {
                    file {
                        url
                        details {
                            size
                        }
                    }
                }
            }
        }
    }
`;

export const Head: HeadFC = ({ location, data }) => {
    const seoData = data as CompatibilityAssessmentQueryType;

    return (
        <SEO data={seoData} location={location} />
    );
};
